<template>
  <section class="Support wrapper" id="faq">
    <h1 v-html="$t('home.faq.title')" />
    <div class="content">
      <div class="FAQ">
        <div class="title">
          {{ $t("home.faq.question_0") }}
        </div>
        <div class="FAQ__item" :class="{active: faqConfigOpened[i]}"
             v-for="(v, i) of faqConfig" :key="i">
          <div class="FAQ__item-info">
            <div class="FAQ__item-question" v-html="v.quest" />
            <div class="FAQ__item-answer" v-html="v.answer" />
          </div>
          <div class="show__more" @click="showMore(i)"></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import supportFaqConfig from './support-faq-config'


export default {
  name: "Support",
  components: {},
  props: [],
  data() {
    return {
      supportFaqConfig,
      faqConfigOpened: [false, false, false, false],
    }
  },
  computed: {
    faqConfig() {
      return [
        {
          quest: this.$t("home.faq.question_0"),
          answer: this.$t("home.faq.answer_0"),
        },
        {
          quest: this.$t("home.faq.question_1"),
          answer: this.$t("home.faq.answer_1"),
        },
        {
          quest: this.$t("home.faq.question_2"),
          answer: this.$t("home.faq.answer_2"),
        },
        {
          quest: this.$t("home.faq.question_3"),
          answer: this.$t("home.faq.answer_3"),
        },
      ]
    },
  },
  methods: {
    showMore(i) {
      this.$set(this.faqConfigOpened, i, !this.faqConfigOpened[i]);
      // if (item.opened) {
      //   item.opened = false;
      // } else {
      //   this.faqConfig.forEach(v => {
      //     this.$set(v, 'opened', false);
      //   });
      //   item.opened = true;
      // }
    },},
  mounted() {
  },
}
</script>

<style lang="scss">
/*  Support  */
.Support {
  width: 100%;
  height: auto;

  .FAQ {
    flex-direction: column;
    //color: #FFFFFF;
    @media (max-width: 992px) {
      width: calc(100% - 64px);
      height: auto;
      padding-bottom: 32px;
    }

    display: flex;
    width: calc(100% - 64px);
    padding: 40px;
    background: #FFFFFF;
    border-radius: 32px;
    margin: 0 0 50px 32px;

    .title {
      margin-bottom: 44px;
      font-size: 56px;
      line-height: 110%;
      letter-spacing: -0.04em;
      color: #000000;

      @media (max-width: 560px) {
        font-size: 32px;
        margin-bottom: 16px;
      }
    }

    &__item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px 0;
      border-top: 1px solid rgba(0, 0, 0, 0.1);

      &-info {
        display: flex;
        flex-direction: column;
        max-width: 756px;

        @media (max-width: 992px) {
          width: calc(100% - 100px);
          max-width: 100%;
          margin-bottom: 16px;
        }

        @media (max-width: 768px) {
          width: 100%;
        }
      }

      &-question {
        font-size: 32px;
        line-height: 130%;
        color: #000000;

        @media (max-width: 560px) {
          font-size: 24px;
        }
      }

      &-answer {
        height: 0;
        opacity: 0;
        margin-top: 0;
        font-size: 20px;
        line-height: 130%;
        color: rgba(0, 0, 0, 0.6);
        overflow: hidden;
        transition: .3s ease;
      }

      .show__more {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        height: 48px;
        border: 1px solid rgba(0, 0, 0, 0.4);
        border-radius: 24px;
        cursor: pointer;

        &::before,
        &::after {
          width: 0;
          height: 18px;
          position: absolute;
          border: 1.5px solid #000000;
          content: '';
          transition: .3s ease;
        }

        &::before {
          transform: rotateZ(90deg);
        }

        @media (max-width: 768px) {
          margin-bottom: 32px;
          width: 100%;
        }
      }

      & + .faq__item {
        margin-top: 44px;

        @media (max-width: 560px) {
          margin-top: 0;
        }
      }

      &.active {
        .FAQ__item-answer {
          height: fit-content;
          opacity: 1;
          margin-top: 8px;
        }

        .show__more {
          &::after {
            transform: rotateZ(90deg);
            opacity: 0;
          }
        }
      }

      @media (max-width: 768px) {
        flex-direction: column;
      }
    }

    @media (max-width: 560px) {
      width: 100%;
      max-width: calc(100% - 64px);
      padding: 16px;
    }
  }
}
</style>