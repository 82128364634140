export default [
  {
    quest: 'Как с нами связаться?',
    answer: 'В личном кабинете создайте обращение. Приоритет на быстрый ответ и полный ответ.\n' +
      'В лив чате на этой странице. Можно получить быстрый ответ на не сложные вопросы.\n' +
        'Telegram\n' + 'Email'
  },
  {
    quest: 'Who are the best startups funded',
    answer: 'You are welcome to apply online, but have a much higher chance of getting an interview with our team via a\n' +
        '          warm intro from an active FundersClub member, or an existing FundersClub portfolio founder',
  },
  {
    quest: 'How are you different from Kickstarter?',
    answer: 'You are welcome to apply online, but have a much higher chance of getting an interview with our team via a\n' +
      '          warm intro from an active FundersClub member, or an existing FundersClub portfolio founder'
  },
  {
    quest: 'How are you different from Kickstarter?',
    answer: 'You are welcome to apply online, but have a much higher chance of getting an interview with our team via a\n' +
      '          warm intro from an active FundersClub member, or an existing FundersClub portfolio founder',
  },
];
